.wrapper {
    height: auto;
    box-shadow: $main_shadow;
}

.header {
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__logo {
        fill: getColor('primary');
        margin-right: auto;
    }

    &__logo img {
        width: 45px;
        height: 45px;
    }

    &__text {
        margin-left: 1rem;
        flex-grow: 1;
        font-size: 20px;
        font-weight: 700;
        color: getColor('primary');
        margin-right: auto;
        text-transform: uppercase;
    }

    &__button {
        cursor: pointer;
        border: none;
        background-color: getColor('white');
    }

    &__button svg {
        width: 50px;
        height: 50px;
    }
}

@media screen and (min-width: 1200px) {
    .header {
        &__logo img {
            width: 60px;
            height: 60px;
        }

        &__text {
            font-size: 34px;
            font-weight: 700;
        }

        &__button svg {
            width: 60px;
            height: 60px;
        }
    }
}
