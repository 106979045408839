body {
  color: #c6824b;
  letter-spacing: .1rem;
  flex-direction: column;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  font-family: Merriweather, sans-serif;
  display: flex;
  position: relative;
}

body:has(.is-open) {
  overflow: hidden;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.home {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  display: flex;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

p a:hover {
  text-decoration: underline;
}

@media screen and (width >= 768px) {
  body {
    max-width: 1199px;
  }

  .container {
    max-width: 1199px;
    padding-left: 50px;
    padding-right: 50px;
  }

  body:has(.is-open) {
    overflow: auto;
  }
}

@media screen and (width >= 1200px) {
  body {
    max-width: 2000px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 0 7px -2px wheat;
  }

  .home {
    min-height: calc(100vh - 410px);
  }

  .container {
    max-width: 1800px;
    padding-left: 100px;
    padding-right: 100px;
  }
}

.wrapper {
  height: auto;
  box-shadow: 0 0 7px -2px wheat;
}

.header {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
}

.header__logo {
  fill: #c6824b;
  margin-right: auto;
}

.header__logo img {
  width: 45px;
  height: 45px;
}

.header__text {
  color: #c6824b;
  text-transform: uppercase;
  flex-grow: 1;
  margin-left: 1rem;
  margin-right: auto;
  font-size: 20px;
  font-weight: 700;
}

.header__button {
  cursor: pointer;
  background-color: #fff;
  border: none;
}

.header__button svg {
  width: 50px;
  height: 50px;
}

@media screen and (width >= 1200px) {
  .header__logo img {
    width: 60px;
    height: 60px;
  }

  .header__text {
    font-size: 34px;
    font-weight: 700;
  }

  .header__button svg {
    width: 60px;
    height: 60px;
  }
}

.slider {
  position: relative;
}

.slider__solution {
  justify-content: center;
  align-items: center;
  display: flex;
}

.slider__header {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

@media screen and (width >= 768px) {
  .slider__header {
    font-size: 32px;
    font-weight: 700;
  }

  .slider__img {
    min-height: 90vh;
  }
}

@media screen and (width >= 1200px) {
  .slider__header {
    font-size: 50px;
    font-weight: 900;
  }
}

.main {
  margin-top: 30px;
}

.main__header {
  text-transform: uppercase;
  color: #4a8795;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 20px;
  line-height: 2;
  display: flex;
}

.main__text {
  justify-content: center;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  font-size: 16px;
  line-height: 2;
  display: block;
}

.content {
  width: 100%;
  margin-top: 0;
  margin-bottom: 30px;
}

@media screen and (width >= 768px) {
  .main {
    margin-top: 50px;
  }

  .main__header {
    margin-bottom: 1rem;
    font-size: 22px;
  }

  .content {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .decor--bottom {
    justify-content: flex-end;
    width: 100%;
    display: flex;
  }
}

@media screen and (width >= 1200px) {
  .main__header {
    font-size: 30px;
  }

  .main__text {
    font-size: 20px;
  }

  .content {
    margin-top: 70px;
    margin-bottom: 70px;
  }
}

.last {
  margin-bottom: 30px;
}

.quicklinks {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.quicklinks__top, .quicklinks__bottom {
  padding-left: 0;
  padding-right: 0;
}

.quicklinks__bottom figure {
  margin-top: 2em;
}

.quicklinks__figure {
  width: 100%;
  margin: auto;
  transition: transform .3s;
  position: relative;
}

.quicklinks__figure img {
  border-radius: 30px;
  max-width: 100%;
  height: auto;
}

.quicklinks__figcaption {
  text-align: center;
  color: #4a8795;
  background-color: #fffc;
  border-radius: 0 0 30px 30px;
  width: 100%;
  margin: 0;
  padding: 10px;
  font-size: 18px;
  position: absolute;
  bottom: 0;
}

.quicklinks__figure:hover {
  transform: scale(1.025);
}

.second {
  margin-top: 2em;
}

@media screen and (width >= 768px) {
  .last {
    margin-bottom: 50px;
  }

  .quicklinks {
    min-height: calc(100vh - 300px);
  }

  .quicklinks__figure {
    text-align: center;
  }

  .quicklinks__figure img {
    max-width: 100%;
    height: auto;
  }

  .quicklinks__figcaption {
    font-size: 24px;
  }
}

@media screen and (width >= 1200px) {
  .last {
    margin-bottom: 80px;
  }

  .quicklinks__top, .quicklinks__bottom {
    gap: 0;
    display: flex;
  }

  .quicklinks__right {
    margin-left: calc(8.33333% - 75px);
    margin-right: calc(16.6667% - 150px);
  }

  .quicklinks__left {
    margin-left: calc(16.6667% - 150px);
    margin-right: calc(8.33333% - 75px);
  }

  .second {
    margin-top: 0;
  }
}

.footer {
  color: #34474b;
  background-color: #c6824b;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  margin: auto;
  display: flex;
}

.footer__text {
  text-transform: uppercase;
  text-align: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: .75em;
  font-size: 20px;
}

.contact {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1em;
  font-size: 14px;
  display: flex;
}

.contact__media {
  color: #34474b;
  align-items: center;
  margin-bottom: .5em;
  text-decoration: none;
  display: flex;
}

.contact__media:hover {
  color: wheat;
  cursor: pointer;
}

.contact__media:hover svg {
  fill: wheat;
}

.contact__media svg {
  fill: #34474b;
  margin-top: .25em;
  margin-right: .5em;
}

@media screen and (width >= 900px) {
  .footer {
    align-items: center;
  }

  .footer__text {
    text-align: center;
    margin-top: 1.5em;
    margin-bottom: 2em;
    font-size: 26px;
  }

  .contact {
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;
    gap: 3em;
    width: 100%;
    margin-bottom: 2em;
    font-size: 18px;
    display: flex;
  }

  .contact__media {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (width >= 1200px) {
  .footer {
    flex-direction: row;
  }

  .footer__text {
    margin-bottom: 2rem;
    font-size: 26px;
  }

  .contact {
    font-size: 18px;
  }

  .contact__media svg {
    width: 40px;
    height: 40px;
    margin-top: .25em;
  }
}

.menu {
  z-index: 100;
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  padding: 70px 50px;
  list-style: none;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(100%);
}

.menu__pages {
  list-style: none;
}

.menu__pages-link {
  color: #c6824b;
  letter-spacing: .02em;
  padding-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.175;
  text-decoration: none;
  display: block;
}

.menu__pages-link:hover, .menu__pages-link:focus, .menu__pages-link:active {
  color: wheat;
}

.menu__toggle {
  cursor: pointer;
  color: #c6824b;
  background-color: #0000;
  border: none;
  position: absolute;
  top: 10px;
  right: 15px;
}

.menu__toggle:hover, .menu__toggle:active {
  color: wheat;
  background-color: #0000;
}

.menu.is-open {
  height: 105vh;
  transform: translateX(0);
}

@media screen and (width >= 768px) {
  .menu.is-open {
    border-radius: 5%;
    width: auto;
    height: auto;
    padding: 30px;
    position: absolute;
    top: 70px;
    left: auto;
    right: 25px;
    overflow: hidden auto;
  }

  .menu__toggle {
    display: none;
  }

  .menu__pages-link {
    font-size: 18px;
    font-weight: 500;
  }
}

@media screen and (width >= 1200px) {
  .menu.is-open {
    padding: 50px;
    top: 90px;
    right: 6%;
  }

  .menu__pages-link {
    font-size: 20px;
  }
}

@media screen and (width >= 1800px) {
  .menu.is-open {
    right: calc(50% - 820px);
  }
}

.section__header {
  text-transform: uppercase;
  color: #4a8795;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 18px;
  line-height: 2;
  display: flex;
}

.section__item {
  margin-bottom: 2rem;
}

.section__item-header {
  text-transform: uppercase;
  color: #4a8795;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 16px;
  line-height: 2;
  display: flex;
}

.section__item-text {
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  padding-left: 0;
  padding-right: 0;
  font-size: 18px;
  line-height: 2;
  display: flex;
}

@media screen and (width >= 768px) {
  .section__header {
    margin-bottom: 1rem;
    font-size: 22px;
  }

  .section__item-header {
    margin-bottom: 1rem;
    font-size: 18px;
  }
}

@media screen and (width >= 1200px) {
  .section__header {
    font-size: 28px;
  }

  .section__item-header, .section__item-text {
    font-size: 26px;
  }
}
/*# sourceMappingURL=cennik.48a8d0fe.css.map */
